<div class="popup-header w-100 d-flex">
    <p class="w-100">
      Report Blocks
    </p>


    <button matTooltip="Save" (click)="saveContent()" [disabled]="!name || !selectedOption" mat-raised-button  color="primary" class="dark">
        <mat-icon>save</mat-icon>
    </button>
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>

<div class="grid-container bg full h-100 position-relative px-3 pt-0">
   
    <div class="card m-1 p-2">
        <div class="form-field mt-2 align-items-center">
            <input id="report-name-input" type="test" maxLength="64" required [(ngModel)]="name"
                 class="form-control input-field report-name-input" placeholder="Add Block Name"
                name="reportName" />
        </div>

          <div class="form-field mt-5 align-items-center">
    <mat-form-field class="w-100">
        <mat-label>Usable Across</mat-label>
        <mat-select placeholder="Usable Across" [(ngModel)]="selectedOption">
          <mat-option [disabled]="isAssetInfo" value="domain">Domain</mat-option>
          <mat-option value="asset">Asset</mat-option>
          <mat-option value="project">Project</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="icon-container d-flex justify-content-center align-items-center mb-3">
        <div class="icon-item" matTooltip="Add asset info block" (click)="assetInfo()" style="cursor: pointer;">
          <img src="assets/assets-info.svg" width="22" alt="Asset Info">
        </div>    
        <div class="icon-item" matTooltip="Add HTML block" (click)="new()" style="cursor: pointer;">
          <mat-icon>code</mat-icon>
        </div>
      </div>
    </div>

    <div class="editor-container editor-styles">
        <div *ngFor="let node of nodes">
            <mat-card>
                <p class="d-flex align-items-center">
                    <span class="me-2">Chapter Title:</span>
                    <input type="text" [(ngModel)]="node.title" style="flex-grow: 1;" class="form-control" />
                    <mat-icon [ngStyle]="{ color: '#EB5757' }" class="ms-2" (click)="removeNode(node.id)">close</mat-icon>
                </p>
                 <div id="editor{{ node.id }}"   contenteditable="true"></div>
                 <!-- <textarea 
                 id="editor{{ node.id }}" 
                 appSummernote 
                
                 (ngModelChange)="updateEditorContent(node.id, $event)">
               </textarea> -->
            </mat-card>
           
        </div>
        <div>

        </div>
    </div>