import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { BackendService } from '../services/backend.service';
import { MatDialog } from '@angular/material/dialog';
import { IReportData, ReportAddDialogComponent } from '../report-add-dialog/report-add-dialog.component';
import { BehaviorSubject, from, combineLatest, Observable, of, pipe, Subject, Subscription } from 'rxjs';
import { Project } from '../projects/item/project.type';
import { catchError, debounceTime, delay, filter, first, map, retry, retryWhen, skip, take, takeUntil, tap } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import { UiService } from '../services/ui.service';
import { v4 as uuidv4 } from 'uuid';
import { ReportDocumentDialogComponent } from './report-document-dialog/report-document-dialog.component';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { ToastrService } from 'ngx-toastr';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';

import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { switchMap } from 'rxjs/operators';
import { GeneralService } from '../services/general.service';
import { environment } from 'src/environments/environment';
import { SolarCsvColumnsComponent } from '../solar-csv-columns/solar-csv-columns.component';
import { StandardTemplateComponent } from './standard-template/standard-template.component';
export interface FilesUploadMetadata {
  uploadProgress$: Observable<number>;
  downloadUrl$: Observable<string>;
}

enum Status {
  SUCCESS = "Success",
  FAILED = "Failed",
  IN_PROGRESS = "Preparing Report",
  SAVED = "Saved",
  UPLOADED = "Uploaded",
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChildren(MatMenu) matMenus: MatMenu;
  @ViewChild(MatSort) sort: MatSort
  public reports$ = new BehaviorSubject<any>(null);
  private reportsData$ = new BehaviorSubject<any>([]);
  public templates;
  public filterTemplates;
  public selectedIndex = 0;
  public projects$: Observable<Project[]>;
  public matSortDirection: SortDirection = 'asc';
  public matSortActive: string = 'edited';
  public status = Status;
  public projectPeoples = [];
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  // public reportsGenerateDatesObject = new Map();
  private isReportsReadyObject = new Map();
  private reportsDocuments = new Map();
  private selectedReportId;
  reportsWithPdfFile = [];
  reportsWithDocxFile = [];
  searchText = '';
  sortActive: string = '';
  isCheckReportsFileUrls = true;
  activeTags;
  private selectedProjChanged$ = new BehaviorSubject<string>(null);
  fileToUpload: File;
  set selectedProj(val: string) {
    this.selectedProjChanged$.next(val);
  }
  get selectedProj(): string {
    return this.selectedProjChanged$.value;
  }

  get selected(): string {
    return this.uiService.selectedProjectId;
  }

  set selected(value: string) {
    this.uiService.selectedProjectId = value;
  }

  getReadOnlyForCurrentUser(): boolean {
    if (this.uiService.selectedProjectId && this.uiService.allProjects.length) {
      return this.generalService.getReadOnlyForCurrentUser(this.uiService.allProjects.find(o => o.id === this.uiService.selectedProjectId));
    }
  }

  private onDestroy$ = new Subject();
  reportSubscription;
  projects;
  asset;
  public isPushData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(
    public backendService: BackendService,
    public dialog: MatDialog,
    public uiService: UiService,
    private toast: ToastrService,
    private storage: AngularFireStorage,
    public generalService: GeneralService
  ) {
    this.loading$.next(true);
    this.uiService.getProjectsEvent$.pipe(
      tap((projects: []) => {
        this.projects$ = of(projects);
        if (projects && projects.length === 0) {
          this.loading$.next(false);
        }
      }),
      takeUntil(this.onDestroy$)
    ).subscribe((projects: []) => {
      if (this.selected) {
        this.projects = projects;
        this.selectedProj = this.selected;
        const project = this.projects.find(o => o.id == this.selected);
        const projectPeople = Object.keys(project.people);
        this.backendService.getProjectPeople(projectPeople).then(users => {
          this.getProjectReport(users);
        })
        this.backendService.getAssetById(project.assetId).subscribe(asset => {
          this.asset = asset.data();
          this.getReportBlocks(this.asset.domain,project.assetId);
        })

      }
    });



    this.uiService.generateReportEvent$.pipe(takeUntil(this.onDestroy$)).subscribe((id) => {
      this.generateReportDocs(id);
    });

    this.uiService.updateReportEvent$.pipe(takeUntil(this.onDestroy$)).subscribe((id) => {
      this.removeDocFromReports(id);
    });
  }

  async getProjectReport(users) {
    this.projectPeoples = users;
    if (this.reportSubscription) {
      this.reportSubscription.unsubscribe();
    }
    this.backendService.getTemplates(this.selected).pipe(takeUntil(this.onDestroy$)).subscribe((templates) => {
      this.filterTemplates = this.templates = templates;
    });
    this.reportSubscription = this.backendService.getReports(this.selected).subscribe(reports => {
      reports.forEach((report, i) => {
        if (report.uid) {
          let find = users.find(o => o && o.uid == report.uid);
          if (find) {
            reports[i].createdByUser = find.firstName ? find.firstName + ' ' + find.lastName : find.email;
          }

          if (report.finalReportData) {
            let find = users.find(o => o && o.uid == report.finalReportData.createdBy);
            if (find) {
              reports[i].finalReportData.createdByUser = find.firstName ? find.firstName + ' ' + find.lastName : find.email;
            }
          }
          /* this.backendService.getUser(report.uid).subscribe(user => {
              reports[i].createdByUser = user.data().firstName + ' ' + user.data().lastName || user.data().email;
            });
  */
        }
      });
      this.loading$.next(false);
      this.reports$.next(reports);
      this.checkReportsFileUrls(reports);
      this.filterBY({ direction: this.matSortDirection, active: this.matSortActive });


    }, error => {
      console.error(error)
      this.loading$.next(false);
    });

    /*  combineLatest([
        this.backendService.getReports(this.selected).pipe(
          // tap(reports => console.log(1111, {reports})),
          map(reports => reports.map(report => ({
            ...report,
            createdAt: report.createdDate ? report.createdDate.toDate() : new Date().toISOString()
          }))),
          map(reports => {
            reports.forEach((report, i) => {
              if (report.uid) {
                this.backendService.getUser(report.uid).subscribe(user => {
                  reports[i].createdByUser = user.data().firstName + ' ' + user.data().lastName || user.data().email;
                });
              }
            });
            return reports;
          }),
        ),
        this.selectedProjChanged$.pipe(skip(1)),
      ]).pipe(
        map(([reports, selected]) => {
          return reports.filter(report => report.reportData && report.reportData.projectId === selected)
        })
      ).subscribe(reports => {
        this.loading$.next(false);
        this.reports$.next(reports);
        // if (this.isCheckReportsFileUrls) {
        //   this.isCheckReportsFileUrls = false;
        this.checkReportsFileUrls(reports);
        // }
      }, error => {
        console.error(error)
        this.loading$.next(false);
      });
      */

  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    if (this.modelSubscription) {
      this.modelSubscription.unsubscribe();
    }
    if (this.labelSubscription) {
      this.labelSubscription.unsubscribe();
    }
  }


  filterBY(sort: Sort) {
    this.matSortActive = sort.active;
    this.matSortDirection = sort.direction;

    const reportsData = this.reports$.getValue();
    const isAsc = sort.direction === 'asc';
    if (!sort.active || sort.direction === '') {
      this.reports$.next(reportsData);
      return;
    }
    switch (sort.active) {
      case 'title':
        isAsc ? this.reports$.next(reportsData.sort((a, b) => 0 - (a.reportData.name.toLocaleLowerCase() > b.reportData.name.toLocaleLowerCase() ? -1 : 1))) : this.reports$.next(reportsData.sort((a, b) => 0 - (a.reportData.name.toLocaleLowerCase() > b.reportData.name.toLocaleLowerCase() ? 1 : -1)));
        break;
      case 'by':
        isAsc ? this.reports$.next(reportsData.sort((a, b) => 0 - (a.createdByUser.toLocaleLowerCase() > b.createdByUser.toLocaleLowerCase() ? -1 : 1))) : this.reports$.next(reportsData.sort((a, b) => 0 - (a.createdByUser.toLocaleLowerCase() > b.createdByUser.toLocaleLowerCase() ? 1 : -1)));
        break;
      case 'on':
        isAsc ? this.reports$.next(
          reportsData.sort((x, y) =>
            y.reportData?.reportGenerated ? -1 : 1)
            .sort((a, b) => 0 - (new Date(a.reportData?.reportGenerated) > new Date(b.reportData?.reportGenerated) ? -1 : 1))) :
          this.reports$.next(
            reportsData.sort((x, y) =>
              x.reportData?.reportGenerated ? -1 : 1)
              .sort((a, b) => 0 - (new Date(a.reportData?.reportGenerated) > new Date(b.reportData?.reportGenerated) ? 1 : -1)));
        break;
      case 'edited':
        isAsc ? this.reports$.next(
          reportsData
            .sort((a, b) => (new Date(a.editedDate ?
              a?.editedDate?.toDate()
              :
              a?.createdDate?.toDate()) > new Date(
                b.editedDate ?
                  b?.editedDate?.toDate()
                  :
                  b?.createdDate?.toDate()
              ) ? -1 : 1))

        )
          :
          this.reports$.next(
            reportsData
              .sort((a, b) => (new Date(a.editedDate ?
                a?.editedDate?.toDate()
                :
                a?.createdDate?.toDate()) > new Date(
                  b.editedDate ?
                    b?.editedDate?.toDate()
                    :
                    b?.createdDate?.toDate()
                ) ? 1 : -1))

          );


        break;
      default:
        return
    }
  }

  encode(url: string) {
    return encodeURIComponent(url);
  }

  checkReportsFileUrls(reports: any[]): void {
    reports.forEach(report => this.checkFileUrls(report._id));
  }

  removeDocFromReports(reportId: string): void {
    this.isReportsReadyObject[reportId] = Status.IN_PROGRESS;
    // this.reportsGenerateDatesObject[reportId] = '';
    delete this.reportsDocuments[reportId];
    this.reportsWithDocxFile = this.reportsWithDocxFile.filter(docId => docId !== reportId);
    this.reportsWithPdfFile = this.reportsWithPdfFile.filter(docId => docId !== reportId);
  }

  generateReportDocsCount = 0;
  generateReportDocs(reportId: string): void {
    const currentReport = this.reports$.value.find(report => report._id === reportId);
    if (currentReport && (currentReport.freeze || !this.isCurrentUser(currentReport.uid))) {

      const reportData = {
        name: currentReport.reportData.name,
        nodes: currentReport.reportData.nodes,
        projectId: currentReport.reportData.projectId,
        isProjectScope: currentReport.reportData.isProjectScope || false,
        projectScope: currentReport.reportData.projectScope || '',
        reportInitiated: new Date().toISOString(),
        templateId: currentReport.reportData.templateId,
        columnLayout: currentReport.reportData.columnLayout || 'two',
        imageBorder: currentReport.reportData.imageBorder || false,
        initialsData: {}
      };
      this.backendService.createReport(
        reportData,
        currentReport.reportData.initialsData, 'in-progress').subscribe((data) => {
          this.uiService.generateReportEvent$.next(data.id);
        });
      return
    }
    this.removeDocFromReports(reportId);

    if (!this.reportsWithDocxFile.includes(reportId) || !this.reportsWithPdfFile.push(reportId)) {

      this.uiService.reportingProcess.push(reportId);

      this.backendService.sendReportForGeneration(reportId).pipe(
        // retry(2),
        // takeUntil(this.onDestroy$)
      ).subscribe(r => {
        let index = this.uiService.reportingProcess.findIndex(o => o == reportId)
        if (index != -1) {
          this.uiService.reportingProcess.splice(index, 1)
        }
        if (r && r.status === 'success') {
          //  this.getDocumentSuccess(reportId);
          // this.backendService.updateReportGeneratedDate(reportId).subscribe();
          this.reportsDocuments[reportId] = {
            docx: r.docx,
            pdf: r.pdf
          };
        } else {
          this.backendService.updateReportStatus(reportId, 'failed').subscribe();
          this.toast.error('', r.message);
        }
      }, error => {
        let index = this.uiService.reportingProcess.findIndex(o => o == reportId)
        if (index != -1) {
          this.uiService.reportingProcess.splice(index, 1)
        }
        this.backendService.updateReportStatus(reportId, 'failed').subscribe();
        this.isReportsReadyObject[reportId] = Status.FAILED;
      });
    }
  }

  checkFileUrls(reportId: string): void {
    const pdfSub = this.backendService.reportSnapshotChanges(reportId).pipe(first()).subscribe(r => {
      pdfSub.unsubscribe();
      if (r.payload.data() && (r.payload.data() as any).reportLocation && (r.payload.data() as any).reportLocation.status === 'success') {
        this.getDocumentSuccess(reportId);
        if (!r.payload.data().reportData.reportGenerated) {
          this.backendService.updateReportGeneratedDate(reportId).subscribe();
        }
        this.reportsDocuments[reportId] = {
          docx: (r.payload.data() as any).reportLocation.docx || (r.payload.data() as any).reportLocation.docxURL,
          pdf: (r.payload.data() as any).reportLocation.pdf || (r.payload.data() as any).reportLocation.pdfURL
        };
      }
      else if (r.payload.data() && (r.payload.data() as any).reportLocation && (r.payload.data() as any).reportLocation.status === 'failed') {
        this.getDocumentFailed(reportId);
      }
      else if (r.payload.data() && (r.payload.data() as any).reportLocation && (r.payload.data() as any).reportLocation.status === 'saved') {
        this.isReportsReadyObject[reportId] = Status.SAVED;

      }
      else if (r.payload.data() && (r.payload.data() as any).reportLocation && (r.payload.data() as any).reportLocation.status === 'uploaded') {
        this.isReportsReadyObject[reportId] = Status.UPLOADED;
        this.reportsWithPdfFile.push(reportId);
        this.reportsDocuments[reportId] = {
          pdf: (r.payload.data() as any).reportLocation.pdf
        };
      }
      else {
        this.isReportsReadyObject[reportId] = Status.IN_PROGRESS;
      }


    }, error => {
      this.isReportsReadyObject[reportId] = Status.FAILED;
    });
  }

  getDocumentSuccess(reportId: string): void {
    this.isReportsReadyObject[reportId] = Status.SUCCESS;
    // this.reportsGenerateDatesObject[reportId] = new Date().toISOString();
    this.reportsWithPdfFile.push(reportId);
    this.reportsWithDocxFile.push(reportId);
  }

  getDocumentFailed(reportId: string): void {
    this.isReportsReadyObject[reportId] = Status.FAILED;
    this.reportsWithPdfFile = this.reportsWithPdfFile.filter(report => report !== reportId);
    this.reportsWithDocxFile = this.reportsWithDocxFile.filter(report => report !== reportId);
  }

  downloadPdf(url: string, id: string, name: string): void {
    if (this.reportsWithPdfFile.includes(id) && (this.reportsDocuments[id] && this.reportsDocuments[id].pdf || url)) {
      window.open(this.reportsDocuments[id].pdf || url);
      // this.backendService.downloadPdfFile(id, name);
    }
  }

  downloadCSV(url: string, id: string, name: string): void {
    window.open(url);
  }

  openPdf(url: string): void {
    window.open(url);
  }
  isMacOS(): boolean {
    return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  }
  downloadDocx(url: string, id: string, name: string, report): void {
    if (this.reportsWithDocxFile.includes(id) && (this.reportsDocuments[id] && this.reportsDocuments[id].docx || url)) {
      // Create an anchor element

      if (!this.isMacOS()) {
        report.downloading = true;
        fetch(this.reportsDocuments[id].docx || url)
          .then(response => response.blob())
          .then(blob => {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.style.display = 'none';
            a.download = name + '.docx';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            report.downloading = false;
            URL.revokeObjectURL(objectUrl);
          })
          .catch(error => {
            report.downloading = false;
            console.error('Error downloading the file:', error)
          });

      } else {
        window.open(this.reportsDocuments[id].docx || url);
      }

      // this.backendService.downloadDocxFile(id, name);
    }
  }

  downloadDocxTemplate(url): void {
    if (url) {
      window.open(url);
    }
  }

  removeReport(id: string): void {
    this.backendService.removeReport(id).subscribe();
  }

  removeFinalReport(id: string): void {
    this.backendService.removeFinalReport(id);
  }

  createOrEditReport(report = null): void {
    const defaultTemplate = this.templates ? this.templates.find(template => template.isDefault) : null;
    const data: IReportData | null = report === null ? {
      name: null,
      id: null,
      projectId: null,
      templateId: null,
      nodes: null,
      freeze: null,
      userUid: null,
      projectInitials: this.reports$.value.map(o => o.reportData.initialsData),
      templates: this.templates,
      selectedInital: null

    } : {
      name: report.reportData.name,
      id: report._id,
      projectId: report.reportData.projectId,
      templateId: report.reportData.templateId || report.reportData.template_id,
      nodes: report.reportData.nodes,
      freeze: report.freeze,
      userUid: report.uid,
      projectInitials: this.reports$.value.map(o => o.reportData.initialsData),
      templates: this.templates,
      selectedInital: report.reportData.initialsData,
      isProjectScope: report.reportData.isProjectScope,
      columnLayout: report.reportData.columnLayout || 'two',
      imageBorder: report.reportData.imageBorder || false,
    };
    let projectsSubsc = new Subscription();
    projectsSubsc = this.projects$.subscribe((projects) => {
      projectsSubsc.unsubscribe();
      const currentProject = projects.find(item => item.id === this.selectedProj)

      if (currentProject && data && data.initialsData && !data.initialsData.projectName) {
        // data.initialsData.projectName = currentProject.name;
        data.initialsData.projectName = '';
      }

      this.dialog.open(ReportAddDialogComponent, {
        width: '70vw',
        height: '100%',
        panelClass: 'bg-dialog',
        data: (!data && defaultTemplate) ?
          {
            templateId: defaultTemplate.id,
          } : data
      });
    });
  }

  removeEmailProvider(email: string): string {
    return (email && email.indexOf('@') !== -1) ? email.substring(0, email.indexOf('@')) : email;
  }

  removeTemplate(id) {
    this.backendService.removeTemplate(id).subscribe(() => { });
  }

  emailSend(report) {
    this.backendService.sendReportMail(this.projectPeoples, report, false, this.asset?.assetName).then(() => {
      this.toast.success('email sent successfully');
    })
  }

  emailFinalReportSend(report) {
    this.backendService.sendReportMail(this.projectPeoples, report, true, this.asset?.assetName).then(() => {
      this.toast.success('email sent successfully');
    })
  }

  changeProject(id): void {
    this.selectedProj = this.selected = id;
    if (id) {
      const projectPeople = Object.keys(this.projects.find(o => o.id == this.selected)?.people);
      if (projectPeople) {
        this.backendService.getProjectPeople(projectPeople).then(users => {
          this.getProjectReport(users);
        })
      }
    }

    this.uiService.changeProjectEvent$.next();

  }



  isCurrentUser(userId: string): boolean {
    return this.backendService.getCurrentUser().uid === userId;
  }

  changeFreeze(id: string, freeze: boolean) {
    if (this.isFinalReportFreeze) {
      this.backendService.changeFinalReportFreeze(id, !freeze)
    } else {
      this.backendService.changeReportFreeze(id, !freeze);
    }

  }

  selectedMatMenuId: string;
  isFinalReportFreeze: boolean = false;
  toggleFreeze(id: string, freeze: boolean) {
    this.selectedMatMenuId = null;
    this.isFinalReportFreeze = false;
    if (freeze) {
      this.changeFreeze(id, freeze);
    } else {
      this.selectedMatMenuId = id;
      this.menuTrigger.openMenu()
    }
  }

  toggleFinalReportFreeze(id: string, freeze: boolean) {
    if (!freeze) {
      this.selectedMatMenuId = id;
      this.isFinalReportFreeze = true;
      this.menuTrigger.openMenu()
    } else {
      this.backendService.changeFinalReportFreeze(id, !freeze)
    }
  }

  closeFreezeMenu() {
    this.menuTrigger.closeMenu();
    this.isFinalReportFreeze = false;
    this.selectedMatMenuId = null;
  }

  copy(report) {
    this.generateReportDocs(report._id)
  }

  uploadFinalReport(reportId) {
    this.selectedReportId = reportId;
    document.getElementById('final_files').click()
  }

  getFile(event) {
    if (event.target.files.length) {
      const file = event.target.files[0]
      const folderPath = `final-reports`;
      const index = this.reports$.value.findIndex(o => o._id == this.selectedReportId)
      this.reports$.value[index].finalReportData = {
        createdByUser: this.backendService.currentUser.displayName,
        pdf: null,
        createdDate: null,
        freeze: false,
        status: 'uploading'
      }

      this.reports$.next(this.reports$.value);
      const { downloadUrl$, uploadProgress$ } = this.uploadFileAndGetMetadata(
        folderPath,
        file,
      );
      downloadUrl$
        .pipe(
          takeUntil(this.onDestroy$),
          catchError((error) => {
            console.log(error);
            throw error;
          }),
        )
        .subscribe((downloadUrl) => {
          this.backendService.finalReport(downloadUrl, this.selectedReportId);
        });
    }
  }

  uploadFileAndGetMetadata(folderPath: string,
    fileToUpload: File): FilesUploadMetadata {
    const { name } = fileToUpload;
    const filePath = `${folderPath}/}${name}`;
    const uploadTask: AngularFireUploadTask = this.storage.upload(
      filePath,
      fileToUpload,
    );
    return {
      uploadProgress$: uploadTask.percentageChanges(),
      downloadUrl$: this.getDownloadUrl$(uploadTask, filePath),
    };
  }

  private getDownloadUrl$(
    uploadTask: AngularFireUploadTask,
    path: string,
  ): Observable<string> {
    return from(uploadTask).pipe(
      switchMap((_) => this.storage.ref(path).getDownloadURL()),
    );
  }

  reportView(report) {
    this.dialog.open(ReportDocumentDialogComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'bg-dialog',
      data: {
        report: report,
        projectId: this.selected
      }
    });

  }

  pushData() {
    this.isPushData$.next(true);
    this.backendService.getDefectData(this.selected).pipe(takeUntil(this.onDestroy$)).subscribe((result) => {
      if (result) {
        if (result.json.defects.length == 0) {
          this.isPushData$.next(false);
          this.toast.error('', 'No annotation found!');
          return;
        }
        if (result.project.assetId) {
          this.backendService.getAssetsById(result.project.assetId).pipe(takeUntil(this.onDestroy$)).subscribe((assets) => {
            if (assets) {
              const data = assets.data();
              result.json["assetTag"] = data.assetTags;
              result.json["assetId"] = data.assetId;
              result.json["id"] = assets.id;
            }
            this.backendService.fileUpload(result.project, result.json).subscribe((email) => {
              this.isPushData$.next(false);
              this.toast.success('', 'Email sent sucessfully!');
            })
          })

        } else {
          this.backendService.fileUpload(result.project, result.json).subscribe((email) => {
            this.isPushData$.next(false);
            this.toast.success('', 'Email sent sucessfully!');
          })
        }
      }
      else {
        this.isPushData$.next(false);
        this.toast.error('', 'No images found!');
      }
    });
  }

  isSearch: boolean = false;
  searchToggle() {
    this.isSearch = !this.isSearch;
  }


  getSearch(event) {
    const search = event.target.value;
    if (search) {
      this.filterTemplates = this.templates.filter(template => {
        return template.title.toLocaleLowerCase()?.includes(search.toLocaleLowerCase());
      });
    } else {
      this.filterTemplates = this.templates;
    }
  }

  createCSV() {
    const project = this.uiService.allProjects.find(o => o.id === this.selected);
    this.modelSubscription = this.backendService.get2DDXFModels$(project.assetId).pipe(takeUntil(this.onDestroy$)).subscribe((result: any) => {
      this.modelSubscription.unsubscribe();
      if (result) {
        if (result.dxfStatus != 'success') {
          this.toast.warning("Panel layer not generated");
          return;
        }
        const dialogRef = this.dialog.open(SolarCsvColumnsComponent, {
          data: {
            projectType: project.projectType,
            projectId: this.selected,
            levels: result.levels
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            const reportData = {
              name: "report",
              nodes: [],
              columns: result,
              projectId: this.selected,
              isCSV: true,
              projectType: project.projectType,
              initialsData: {}
            };

            return this.backendService.createReport(
              reportData, null, 'in-progress'
            ).pipe(
              takeUntil(this.onDestroy$)
            ).subscribe((result) => {
              // this.uiService.generateReportEvent$.next(id);
            });

          }
        });
      } else {
        this.toast.warning("DXF not uploadd yet");
      }
    });



  }

  modelSubscription: Subscription;
  isProcessing: boolean = false;
  createCSV1() {
    const project = this.uiService.allProjects.find(o => o.id === this.selected);
    if (this.modelSubscription) {
      this.modelSubscription.unsubscribe();
    }
    this.isProcessing = true;
    this.modelSubscription = this.backendService.get2DDXFModels$(project.assetId).pipe(takeUntil(this.onDestroy$)).subscribe((result: any) => {
      if (result) {
        this.modelSubscription.unsubscribe();
        if (result.labelsFile) {
          this.backendService.getFile(result.labelsFile).subscribe((labels: any) => {
            const panelLabels = JSON.parse(labels) || [];
            this.getDXFLinkedImages(result.id, result.assetId, panelLabels)
          })
        } else {
          this.isProcessing = false;
          this.toast.warning("No labels added yet.");
          // close loading
        }

      } else {
        // close loading
        this.isProcessing = false;
        this.toast.warning("Base Model not added yet");
      }
    })
  }

  labelSubscription: Subscription;
  getDXFLinkedImages(modelId: string, assetId, labels) {
    const projectId = this.selected;
    if (this.labelSubscription) {
      this.labelSubscription.unsubscribe();
    }
    this.labelSubscription = this.backendService
      .getDXFLinkedImages(assetId, modelId, projectId).subscribe((linkImages: any) => {
        if (linkImages && linkImages.linkFile) {
          this.labelSubscription.unsubscribe();
          this.backendService.getFile(linkImages.linkFile).subscribe((medias: any) => {
            const mediaFile = JSON.parse(medias) || [];
            const labelImages = Object.keys(mediaFile);
            let images = [];
            labelImages.forEach(key => {
              if (mediaFile[key] && mediaFile[key]['images']) {
                images = images.concat(mediaFile[key]['images']);
              }

            });
            images = images.filter(this.generalService.onlyUnique);
            let contextImages = this.uiService.projectImageContexts.filter(o => o.tags.length ||
              o.hotspots.length || o.coldspots.length || o.thermalPolygons.length);
            const reqImages = contextImages.filter(record => images.includes(record.id));
            if (reqImages.length == 0) {
              this.isProcessing = false;
              this.toast.warning("No annotations found across all iamges");
              return;
            }
            this.toast.warning("Processing your images will take 15-20 mins to complete");
            this.backendService.getHandlesForBatch(reqImages.map(o => o.id), projectId)
              .subscribe(
                response => {
                  let mergedResults = [];
                  response.forEach(el => {
                    if (el.status === 'success') {
                      mergedResults = mergedResults.concat(el.data);
                    }
                  });

                  labelsMapping(labels, reqImages, mergedResults).then(result => {
                    const project = this.uiService.allProjects.find(o => o.id === projectId);

                    result = result.filter(o => o.panelImages.length);
                    let csvData = []
                    const subs = this.backendService.getTags(projectId).pipe(takeUntil(this.onDestroy$)).subscribe(({ tags }) => {
                      subs.unsubscribe();
                      let activeTags = tags.filter(
                        (x) => x.status !== 'de-active'
                      ).reduce((acc, tag) => tag.sensitivity ? [...acc, tag] : acc, []);
                      ;
                      result.forEach(label => {
                        label.panelImages.forEach(panel => {
                          const severityTitle = panel.sensitive && panel.tag ? activeTags.find(o => o.tag === panel.tag)?.levels?.find(o => o.level == panel.sensitive).title : '';
                          csvData.push({
                            'PDT': label.L1,
                            'BJ': label.L2,
                            'TR': label.L3,
                            'STR': label.L4,
                            'PNL': label.L5,
                            'Name': panel.fileName,
                            'url': `https://${environment.firebase.projectId}.web.app/dashboard/projects/${this.selected}/images/${panel.imageId}`,
                            'Temperature': panel.temperature ?
                              panel.temperature + '°C' :
                              (panel.minTemperature && panel.maxTemperature)
                                ?
                                panel.minTemperature + '°C - ' + panel.maxTemperature + '°C'
                                : '',
                            'Tag': panel.tag || '',
                            'severity level': panel.sensitive || '',
                            'Severity': severityTitle,
                            'Notes': panel.note || '',
                          })
                        });


                      });
                      this.isProcessing = false;
                      if (project.projectType !== 'thermal') {
                        csvData = csvData.map(({ Temperature, ...rest }) => rest);
                      }
                      this.generateCSV(csvData, "report.csv", projectId);
                    })
                  })
                    .catch(error => {
                      this.isProcessing = false;
                      this.toast.error("API not responding. contact with support.")
                      console.error('Error during processing', error);
                    });

                },
                error => {
                  console.error('Error fetching data:', error);
                }
              );

            function labelsMapping(data, annotatedImages, results, out = []) {
              let res: any = {
                'L1': '',
                'L2': '',
                'L3': '',
                'L4': '',
                'L5': '',
                panelImages: []
              };

              // Function to process a single layer
              const processLayer = async (layer) => {
                res[layer.layer] = layer.name;

                if (layer.layer === 'L5') {
                  const panelImages = [];
                  await Promise.all(annotatedImages.map(async (ctxImage) => {
                    const image = results.find(o => o.imageId == ctxImage.id);
                    if (image) {

                      await Promise.all(ctxImage.hotspots.map(async (polygon) => {
                        const findPolygon = image.hotspots.find(o => o.polygonId === polygon.id);
                        if (findPolygon && findPolygon.handles.includes(layer.panels[0])) {
                          panelImages.push({
                            handle: layer.panels[0],
                            polygonId: polygon.id,
                            imageId: image.imageId,
                            fileName: ctxImage.fileName,
                            closedType: polygon.polygon.closedType,
                            temperature: polygon.temperature,
                            maxTemperature: polygon.maxTemperature,
                            minTemperature: polygon.minTemperature,
                            note: polygon.note,
                            sensitive: polygon.sensitive,
                            tag: polygon.tag
                          });
                        }
                      }));

                      await Promise.all(ctxImage.coldspots.map(async (polygon) => {
                        const findPolygon = image.coldspots.find(o => o.polygonId === polygon.id);
                        if (findPolygon && findPolygon.handles.includes(layer.panels[0])) {
                          panelImages.push({
                            handle: layer.panels[0],
                            polygonId: polygon.id,
                            imageId: image.imageId,
                            fileName: ctxImage.fileName,
                            closedType: polygon.polygon.closedType,
                            temperature: polygon.temperature,
                            maxTemperature: polygon.maxTemperature,
                            minTemperature: polygon.minTemperature,
                            note: polygon.note,
                            sensitive: polygon.sensitive,
                            tag: polygon.tag

                          });
                        }
                      }));

                      await Promise.all(ctxImage.tags.map(async (polygon) => {
                        const findPolygon = image.polygons.find(o => o.polygonId === polygon.id);
                        if (findPolygon && findPolygon.handles.includes(layer.panels[0])) {
                          panelImages.push({
                            handle: layer.panels[0],
                            polygonId: polygon.id,
                            imageId: image.imageId,
                            fileName: ctxImage.fileName,
                            closedType: polygon.polygon.closedType,
                            temperature: polygon.temperature,
                            maxTemperature: polygon.maxTemperature,
                            minTemperature: polygon.minTemperature,
                            note: polygon.note,
                            sensitive: polygon.sensitive,
                            tag: polygon.tag

                          });
                        }
                      }));

                      await Promise.all(ctxImage.thermalPolygons.map(async (polygon) => {
                        const findPolygon = image.thermalPolygons.find(o => o.polygonId === polygon.id);
                        if (findPolygon && findPolygon.handles.includes(layer.panels[0])) {
                          panelImages.push({
                            handle: layer.panels[0],
                            polygonId: polygon.id,
                            imageId: image.imageId,
                            fileName: ctxImage.fileName,
                            closedType: polygon.polygon.closedType,
                            temperature: polygon.temperature,
                            maxTemperature: polygon.maxTemperature,
                            minTemperature: polygon.minTemperature,
                            note: polygon.note,
                            sensitive: polygon.sensitive,
                            tag: polygon.tag
                          });
                        }
                      }));

                    }
                  }));
                  res.panelImages = panelImages;
                  out.push({ ...res }); // Create a copy of res to prevent reference issues
                }

                if (layer.children) {
                  // Process the children layer and wait for it to complete
                  await processLayerRecursive(layer.children);
                }
              };

              // Recursive function to process all layers
              const processLayerRecursive = async (layers) => {
                for (const layer of layers) {
                  await processLayer(layer);
                }
              };

              // Start processing the layers
              return processLayerRecursive(data).then(() => out);
            }


          })
        } else {
          this.isProcessing = false;
          this.toast.warning("No image linked to labels yet.")
        }
      }, (error) => {
        throw error;
      });
  }

  generateCSV(data: any[], filename: string, projectId: string) {
    const finalHeaders = Object.keys(data[0]);
    const csvContent =
      [finalHeaders.join(',')].concat(data.map(row => finalHeaders.map(header => `"${row[header]}"`).join(','))).join('\n');

    const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8' });
    const file = new File([blob], filename + '.csv', { type: 'text/csv' });
    const reportData = {
      name: filename,
      isCSV: true,
      projectId: projectId,
      initialsData: {}
    };
    this.backendService.uploadCSVFile(file).subscribe((csv) => {
      reportData['csv'] = csv.link;
      return this.backendService.createReport(
        reportData, null, 'success'
      );
    });
  }
  zipProcess(report) {
    report.zipProcess = true;
    const project = this.projects.find(o => o.id == this.selected);
    const lists = [];
    const fileName = `${this.asset.assetName}-${project.name}-${project.inspectionDate}`;

    // Add reportLocation files if they exist
    if (report.reportLocation?.pdf) {
      lists.push({
        fileName: `${fileName}.pdf`,
        url: report.reportLocation.pdf
      });
    }
    if (report.reportLocation?.docx) {
      lists.push({
        fileName: `${fileName}.docx`,
        url: report.reportLocation.docx
      });
    }

    // Helper function to process nodes
    const processNodes = (nodes, fileType) => {
      return nodes
        .filter(o => o[fileType])
        .flatMap(node => (node.urls || []).map(url => ({
          fileName: url.fileName,
          url: url.url
        })));
    };

    // Add document URLs if they exist
    const documents = processNodes(report.reportData?.nodes || [], 'isDocuments');
    lists.push(...documents);

    // Add certificate URLs if they exist
    const certificates = processNodes(report.reportData?.nodes || [], 'isCertificates');
    lists.push(...certificates);
    console.log(lists,report._id);
    this.backendService.getDocumentZip(report._id , lists).subscribe((csv) => {
      report.zipProcess = false;
    }, error => {
      report.zipProcess = false;
    });
  }

  isDocument(report) {
    return (report.reportLocation?.status === 'success' && report.reportData?.nodes?.filter(o => o.isDocuments)?.length > 0) ? true : false
  }

  newTemplate(){
    const project = this.uiService.allProjects.find(o => o.id === this.selected);
    this.dialog.open(StandardTemplateComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'bg-dialog',
      data:{
        asset: this.asset,
        projectId:this.selected,
        assetId:project.assetId
      }
    });
  }

  reportBlocks=[];
  getReportBlocks(domain,assetId){
    this.backendService.getReportsBlocks(domain).pipe(takeUntil(this.onDestroy$)).subscribe((blocks) => {
      this.reportBlocks= blocks.filter(o => o.assetId === assetId || o.projectId === this.selected);
    })
  }

  deleteBlock(block){
    this.backendService.deleteReportBlock(block.id,block.domain).then();
  }

  editBlock(block){
     this.dialog.open(StandardTemplateComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'bg-dialog',
      data:{
        asset: this.asset,
        projectId:block.projectId,
        assetId:block.assetId,
        block:block
      }
    });

  }

  
}


